<template>
  <v-card>
    <v-card-title style="padding-top: 2px" class="px-5 pb-0">
      Active Allowances - {{ item.description }}
      <v-spacer></v-spacer>
      <v-card-subtitle class="pa-y ma-0">
        Item ID: {{ item.item_id }}
      </v-card-subtitle>
    </v-card-title>
    <v-divider></v-divider>
    <v-container fluid class="pa-0">
      <v-data-table
        :headers="headers"
        :items="currentAllowances"
        dense
        :height="tableHeight"
        fixed-header
        disable-pagination
        :footer-props="{'items-per-page-options': [-1]}"
        :items-per-page="-1"
        sort-by="status_constant">
        <template v-slot:[`item.status_constant`]="{ item }">
          <v-chip
            v-if="item && item.status_constant"
            small
            dark
            :color="item.statusColor">
            {{ item.statusText }}
          </v-chip>
        </template>
        <template v-slot:[`item.cost_stack_include`]="{ item }">
          <v-icon class="ma-0 pa-0" :color="item.cost_stack_include ? 'success' : 'red'">
            {{  item.cost_stack_include ? 'mdi-check' : 'mdi-close' }}
          </v-icon>
        </template>
        <template v-slot:[`item.promo_name`]="{ item }">
          <span
            @click="$emit('goToPromo', item.promo_id)"
            class="primary--text"
            style="cursor: pointer">
            {{ item.promo_name }}
          </span>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: 'ActiveAllowances',
  data () {
    return {
      headerProps: {
        sortable: false,
        class: 'accent white--text'
      },
      allowanceFields: [
        'ad_scan', 'edlc_scan', 'tpr_scan',
        'amap_allowance', 'early_buy_allowance', 
        'ad_billback', 'edlc_billback', 'tpr_billback'
      ]
    }
  },
  props: {
    item: Object,
    allowances: Array
  },
  computed: {
    currentAllowances () {
      if (!this.allowances || this.allowances.length === 0) return []
      return this.allowances.map(a => this.formatAllowance(a))
    },
    baseHeaders () {
      return [
        { text: 'Cost Stack',  value: 'cost_stack_include', align: 'center', show: this.$auth.tenant === 'alliance-retail-group' },
        { text: 'Status',  value: 'status_constant', align: 'center', },
        { text: 'Promo Name',  value: 'promo_name', width: '15%' },
        { text: 'Promo Type',  value: 'billing_mode', show: this.$auth.tenant !== 'alliance-retail-group' },
        { text: 'Promo Category',  value: 'promo_category_name', show: this.$auth.tenant === 'alliance-retail-group', width: '10%' },
        { text: 'Start Date',  value: 'start_dt' },
        { text: 'End Date',  value: 'end_dt' },
        { text: 'AMAP',  value: 'amap_allowance' },
        { text: 'EBA', value: 'early_buy_allowance' },
        { text: 'Ad BB', value: 'ad_billback' },
        { text: 'EDLC BB',  value: 'edlc_billback' },
        { text: 'TPR BB',  value: 'tpr_billback' },
        { text: 'Ad Scan',  value: 'ad_scan' },
        { text: 'EDLC Scan',  value: 'edlc_scan' },
        { text: 'TPR Scan',  value: 'tpr_scan' }
      ].filter(header => (!header.hasOwnProperty('show') || header.show))
    },
    headers() {
      return this.baseHeaders.map(header => {
        return { ...this.headerProps, ...header }
      })
    },
    tableHeight () {
      return this.currentAllowances?.length >= 12 ? '384px' : undefined
    }
  },
  methods: {
    formatAllowance (allowance) {
      for (const field of this.allowanceFields) {
        const value = allowance[field]
        allowance[field] = (value > 0) ? `$${this.$config.formatCurrency(value)}` : '-'
      }
      return {
        ...allowance,
        start_dt: this.$config.formatDate(allowance.start_dt),
        end_dt: this.$config.formatDate(allowance.end_dt),
        statusText: this.getStatusText(allowance.status_constant),
        statusColor: this.getStatusColor(allowance.status_constant)
      }
    },
    getStatusText (status_constant) {
      if (status_constant.toLowerCase() === 'pending_submission') {
        return 'PENDING'
      }
      return status_constant.replaceAll('_', ' ')
    },
    getStatusColor (status_constant) {
      switch (status_constant.toLowerCase()) {
        case 'pending_submission':
          return 'red'
        case 'submitted':
          return 'orange'
        case 'approved':
          return 'green'
        case 'rejected':
          return 'black'
        case 'changes_requested':
          return 'red'
        case 'cancelled':
          return 'grey lighten-1'
      }
    }
  }
}
</script>