var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "px-5 pb-0", staticStyle: { "padding-top": "2px" } },
        [
          _vm._v(" Active Allowances - " + _vm._s(_vm.item.description) + " "),
          _c("v-spacer"),
          _c("v-card-subtitle", { staticClass: "pa-y ma-0" }, [
            _vm._v(" Item ID: " + _vm._s(_vm.item.item_id) + " ")
          ])
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.currentAllowances,
              dense: "",
              height: _vm.tableHeight,
              "fixed-header": "",
              "disable-pagination": "",
              "footer-props": { "items-per-page-options": [-1] },
              "items-per-page": -1,
              "sort-by": "status_constant"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.status_constant",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item && item.status_constant
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                small: "",
                                dark: "",
                                color: item.statusColor
                              }
                            },
                            [_vm._v(" " + _vm._s(item.statusText) + " ")]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.cost_stack_include",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: {
                            color: item.cost_stack_include ? "success" : "red"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.cost_stack_include
                                  ? "mdi-check"
                                  : "mdi-close"
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.promo_name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "primary--text",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("goToPromo", item.promo_id)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.promo_name) + " ")]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }